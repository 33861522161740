import React from 'react'

export const Section = ({ className, children, background }) => {

    return (
        <div className={`relative py-150 bg-cover bg-center ${className}`} style={{ backgroundImage: `url(${background})` }}>
            {children}
        </div>
    );
};
