import React from 'react'
import PropTypes from 'prop-types'

export const Title = ({ className, children }) => {

    return (
        <h4 className={`text-csw-dark font-medium cursor-default mb-5 text-3xl ${className}`}>{children}</h4>
    );
};

Title.propTypes = {
    className: PropTypes.string,
};