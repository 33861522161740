import React from 'react'
import PropTypes from 'prop-types'

export const Text = ({ className, children }) => {

    return (
        <div>
            <p className={`text-csw-grey cursor-default mb-5 text-xl ${className}`}>{children}</p>
        </div>
    );
};

Text.propTypes = {
    className: PropTypes.string,
};