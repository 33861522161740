import React from 'react'
import PropTypes from 'prop-types'

export const Subtitle = ({ className, children }) => {

    return (
        <h3 className={`text-csw-dark-50 mb-5  font-medium cursor-default text-xl uppercase ${className}`}>{children}</h3>
    );
};

Subtitle.propTypes = {
    className: PropTypes.string,
};