import React from 'react'
import PropTypes from 'prop-types'

export const BigWord = ({ className, children }) => {

    return (
        <span className={`text-csw-grey-50 ase-in cursor-default duration-300 hover:text-csw-dark mb-5 mx-10 my-5 inline-block font-bold z-30 relative text-xl ${className}`}>{children}</span>
    );
};

BigWord.propTypes = {
    className: PropTypes.string,
};