import React from 'react'
import PropTypes from 'prop-types'

export const ModalTitle = ({ className, children }) => {

    return (
        <h6 className={`text-csw-orange mb-5 font-bold cursor-default text-xl uppercase ${className}`}>{children}</h6>
    );
};

ModalTitle.propTypes = {
    className: PropTypes.string,
};