import React, { useState } from 'react'
import { Container } from '../components/Container.js'
import { Button } from '../components/Button.js'
import { Section } from '../components/Section.js'
import { Title } from '../components/Title.js'
import { ModalTitle } from '../components/ModalTitle.js'
import { BigWord } from '../components/BigWord.js'
import { Subtitle } from '../components/Subtitle.js'
import { Text } from '../components/Text.js'
import { ReactComponent as ArrowIcon } from '../assets/icon/arrow.svg'
import { ReactComponent as LineIcon } from '../assets/icon/line.svg'
import Header from '../assets/img/main-photo.jpg'
import Resource from '../assets/img/white-resource.png'
import Elements from '../assets/img/elements.png'
import Elements2 from '../assets/img/elements-2.png'
import Elements3 from '../assets/img/elements-3.png'
import Elements4 from '../assets/img/elements-4.png'
import LinkedinIcon from '../assets/icon/linkedin-icon.png'
import EmailIcon from '../assets/icon/email-icon.png'
import Photo1 from '../assets/img/photo-1.jpg'
import Photo2 from '../assets/img/photo-2.jpg'
import { ReactComponent as SoftwareIcon } from '../assets/icon/software.svg'
import { ReactComponent as RocketIcon } from '../assets/icon/rocket.svg'
import { ReactComponent as OutsourcingIcon } from '../assets/icon/outsourcing.svg'
import { ReactComponent as ConsultancyIcon } from '../assets/icon/consultancy.svg'
import Logo from '../assets/img/logo.svg'
import { Modal } from '../components/Modal.js'
import { Input } from '../components/forms/Input.js'
import { Select } from '../components/forms/Select.js'
import { Textarea } from '../components/forms/Textarea.js'
import axios from 'axios'
import { SITE_CONFIG } from '../settings.js'
import Cookies from 'universal-cookie'

const selectOptions = [
    { value: 'budget', label: 'Pedir presupuesto' },
    { value: 'information', label: 'Más información' },
    { value: 'work', label: 'Trabaja con nosotros' },
    { value: 'other', label: 'Otro' },
]

export default function Main() {
    
    const cookies = new Cookies();
    const [showCookies, setShowCookies] = useState(() => {
        return cookies.get('showCookies') ? cookies.get('showCookies') : 1
    })
    const [activeService, setActiveService] = useState('startup')
    const [modalState, setModalState] = useState(false)
    const [modalCookiesState, setModalCookiesState] = useState(false)
    const [name, setName] = useState('')
    const [surnames, setSurnames] = useState('')
    const [email, setEmail] = useState('')
    const [select, setSelect] = useState('')
    const [content, setContent] = useState('')
    const [showValidate, setShowValidate] = useState(false)
    const [sending, setSending] = useState(false)

    const sendMail = () => {
        const sendForm = validateForm()
        if (sendForm) {
            setSending(true)
            axios.post(SITE_CONFIG.apiUrl+'mail', {
                params: {
                    name: name,
                    surname: surnames,
                    email: email,
                    option: select,
                    tell_us: content
                }
              })
              .then(function (response) {
                window.location.reload(true)
              })
              .catch(function (error) {
                console.log(error)
                setSending(false)
              })
        }
    }

    const validateForm = () => {
        let formOk = false
        if (name !== '' &&
            surnames !== '' &&
            email !== '' &&
            select !== '' &&
            content !== '') {
                formOk = true
        }

        if (!formOk) {
            setShowValidate(true)
        } else {
            setShowValidate(false)
        }

        return formOk
    }

    const cookiesAction = () => {
        setModalCookiesState(false)
        setShowCookies(0)
        cookies.set('showCookies', 0, { path: '/' });
    }

    return (
        <>
            <div className='z-40 absolute top-0 left-0 w-full'>
                <Container>
                    <div className='grid grid-cols-2 gap-2 py-10 '>
                        <div>
                            <img src={Logo} alt='Logo'/>
                        </div>
                        <div className='text-right'>
                            <span onClick={() => setModalState(true)} className='text-csw-dark font-bold cursor-pointer'>Contacta</span>
                        </div>
                    </div>
                </Container>
            </div>
            <Section className='py-0' background={Header}>
                <Container>
                    <div className='h-700 md:h-900 align-middle flex justify-center items-center pt-90'>
                        <div className='grid grid-cols-1 xl:grid-cols-2 gap-2 z-40'>
                            <div>
                                <h1 className='text-csw-dark mb-5 text-6xl font-bold cursor-default pr-10'>Consultoría de software y desarrollo a medida</h1>
                                <h2 className='text-csw-grey mb-10 text-xl cursor-default'>Desarrollamos tus proyectos a medida, o si ya lo tienes te ayudamos con nuestros servicios de auditoría y consultoría de software</h2>
                                <Button
                                    icon={<ArrowIcon className="ml-3"/>}
                                    onClick={() => setModalState(true)}
                                    >
                                    Hablemos
                                </Button>
                            </div>
                        </div>
                    </div>
                </Container>
                <img src={Elements} alt='Elements img' className='absolute top-0 h-700 md:h-900 right-0 z-30 opacity-10 xl:opacity-100'/>
                <img src={Resource} alt='Reource img' className='absolute top-0 w-full h-700 md:h-900 right-0 z-20 hidden xl:inline'/>
                <img src={Header} alt='Header img' className='absolute top-0 h-700 md:h-900 right-0 z-10 hidden xl:inline'/>
                <div className='bg-csw-white-50 w-full h-100 -mt-1 z-40 relative'></div>
                <div className='bg-csw-white absolute top-0 w-full h-700 md:h-900 z-20 inline xl:hidden opacity-90'></div>
            </Section>
            <Section className='py-140'>
                <Container>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                        <div className='p-10 pt-0 pl-0'>
                            <img src={Photo1} alt='Photo1 img' className='w-full shadow-2xl z-10 relative'/>
                        </div>
                        <div className='mt-10'>
                            <Subtitle>SOMOS EXPERTOS</Subtitle>
                            <Title>Aprovecha <span className='font-bold'>nuestra experiencia</span>, grandes profesionales, metodologías ágiles y tecnologías modernas para llevar tus soluciones a otro nivel</Title>
                            <LineIcon className='mb-5'/>
                            <Text>Aportamos valor, ideas innovadoras y simplificamos el proceso de desarrollo de todos nuestros proyectos.</Text>
                        </div>
                    </div>
                </Container>
                <div className='px-5 md:px-10 text-left md:text-center'>
                    <BigWord>Cloud</BigWord>
                    <BigWord>Serless computing</BigWord>
                    <BigWord>Big data</BigWord>
                    <BigWord>ETLs</BigWord>
                    <BigWord>Analítica de datos</BigWord>
                    <BigWord>Aplicaciones móviles</BigWord>
                    <BigWord>Visualización de datos</BigWord>
                    <BigWord>Aplicaciones web</BigWord>
                    <BigWord>UI / UX</BigWord>
                    <BigWord>DevOps</BigWord>
                    <BigWord>Agile methodologies</BigWord>
                </div>
                <img src={Elements2} alt='Elements 2 img' className='absolute bottom-0 h-400 z-0 left-0 opacity-20 md:opacity-100'/>
            </Section>
            <Section className='bg-csw-dark'>
                <Container>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                        <div>
                            <Subtitle className='text-csw-dark-50'>Mucho más que software a medida</Subtitle>
                            <Title className='text-csw-white-50 font-bold'>Descubre <span className='font-bold'>todas las opciones</span> con las que podemos mejorar tu producto</Title>
                            <LineIcon className='mb-5'/>
                        </div>
                        <div className='pb-10 pt-0 pr-0 pl-0 md:pl-10 mt-0 md:-mt-200'>
                            <img src={Photo2} alt='Photo2 img' className='w-full shadow-2xl z-10 relative'/>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mt-10'>
                        <div className='md:col-span-1 lg:col-span-2'>
                            {activeService === 'startup' ?
                                <>
                                    <Text className='text-csw-white-50 font-bold'>MVP para Startups</Text>
                                    <Text className='text-csw-white-100'>Desarrollamos el producto mínimo viable (MVP) o prueba de concepto (PoC) de tu idea de negocio como aplicación móvil o web de forma ágil.</Text>
                                    <Text className='text-csw-white-100'>Con ello obtendrás feedback de tus usuarios en el menor tiempo posible y podrás definir con inputs reales las siguientes iteraciones o roadmap.</Text>
                                </>
                            : activeService === 'outsourcing' ?
                                <>
                                    <Text className='text-csw-white-50 font-bold'>Externalización de recursos</Text>
                                    <Text className='text-csw-white-100'>Disponemos de un equipo de personas cualificados en los ámbitos de mayor interés.</Text>
                                    <Text className='text-csw-white-100'>Desarrollo de aplicaciones, analítica de datos, instaurar culturas de DevOps, gestión de proyectos… ya sea en remoto o en tus instalaciones. </Text>
                                </>
                            : activeService === 'software' ?
                                <>
                                    <Text className='text-csw-white-50 font-bold'>Desarrollo de software </Text>
                                    <Text className='text-csw-white-100'>Tenemos experiencia y recursos para cubrir todas tus necesidades.</Text>
                                    <Text className='text-csw-white-100'>Diseño de aplicaciones, aplicaciones web o para dispositivos móviles, creación de métricas, plataformas de visualización de datos… </Text>
                                </>
                            :
                                <>
                                    <Text className='text-csw-white-50 font-bold'>Consultoría</Text>
                                    <Text className='text-csw-white-100'>Con nuestros servicios de consultoría analizaremos tus soluciones, te prepararemos un informe detallado con las mejoras y/o cambios a realizar para su optimización.</Text>
                                    <Text className='text-csw-white-100'>Evaluaremos tu infraestructura y sus costes, analizaremos tus bases de datos para detectar inconsistencias y/o optimizar su funcionamiento, diseñaremos y pondremos en marcha flujos de trabajo para tener mayor trazabilidad de éstos, inspeccionaremos tus sistemas de software y comprobaremos tu seguridad para detectar de forma eficaz las áreas de mejora que se traducirán en beneficios para tu empresa, a nivel económicos y/o competitivos. </Text>
                                </>
                            }
                            <Button
                                icon={<ArrowIcon className="ml-3"/>}
                                className='mt-10'
                                onClick={() => setModalState(true)}
                                >
                                Para más información
                            </Button>
                        </div>
                        <div className='pl-0 md:pl-10 mt-20 md:mt-0'>
                            <div 
                                onClick={() => setActiveService('startup')}
                                className={`py-5 px-5 w-full mb-5 border-solid border-2 hover:border-csw-white-50 ase-in duration-300 cursor-pointer ${activeService === 'startup' ? 'border-csw-white-50' : 'border-csw-dark'}`}>
                                <RocketIcon className='inline mr-5'/>
                                <span className='text-csw-white-50 font-bold'>MVP para Startups</span>
                            </div>
                            <div 
                                onClick={() => setActiveService('outsourcing')}
                                className={`py-5 px-5 w-full mb-5 border-solid border-2 hover:border-csw-white-50 ase-in duration-300 cursor-pointer ${activeService === 'outsourcing' ? 'border-csw-white-50' : 'border-csw-dark'}`}>
                                <OutsourcingIcon className='inline mr-5'/>
                                <span className='text-csw-white-50 font-bold'>Externalización de recursos</span>
                            </div>
                            <div 
                                onClick={() => setActiveService('software')}
                                className={`py-5 px-5 w-full mb-5 border-solid border-2 hover:border-csw-white-50 ase-in duration-300 cursor-pointer ${activeService === 'software' ? 'border-csw-white-50' : 'border-csw-dark'}`}>
                                <SoftwareIcon className='inline mr-5'/>
                                <span className='text-csw-white-50 font-bold'>Desarrollo de software </span>
                            </div>
                            <div 
                                onClick={() => setActiveService('consultancy')}
                                className={`py-5 px-5 w-full mb-5 border-solid border-2 hover:border-csw-white-50 ase-in duration-300 cursor-pointer ${activeService === 'consultancy' ? 'border-csw-white-50' : 'border-csw-dark'}`}>
                                <ConsultancyIcon className='inline mr-5'/>
                                <span className='text-csw-white-50 font-bold'>Consultoría</span>
                            </div>
                        </div>
                    </div>
                </Container>
            </Section>
            <Section>
                <Container className='text-center px-0 md:px-150 lg:px-300 z-20 relative'>
                    <Subtitle>¡Ponte en nuestras manos!</Subtitle>
                    <Title>Confía en <span className='font-bold'>nuestra experiencia</span> y la calidad garantizada de nuestros servicios para obtener el mejor proyecto a medida</Title>
                    <div>
                        <LineIcon className='mb-5 inline'/>
                    </div>
                    <Button
                        icon={<ArrowIcon className="ml-3"/>}
                        onClick={() => setModalState(true)}
                        >
                        Contáctanos
                    </Button>
                </Container>
                <img src={Elements3} alt='Elements 3 img' className='absolute top-0 h-400 z-10 right-0 opacity-20 md:opacity-100'/>
                <img src={Elements4} alt='Elements 4 img' className='absolute bottom-0 h-400 z-10 left-0 opacity-20 md:opacity-100'/>
            </Section>
            <Section className='py-0'>
                <Container className='bg-csw-dark py-10 z-10 relative'>
                    <div className='grid grid-cols-1 md:grid-cols-5 gap-2'>
                        <div></div>
                        <div className='text-center mb-10 md:mb-0'>
                            <span className='text-csw-white-50 font-bold text-3xl block cursor-default'>52</span>
                            <span className='text-csw-white-100 cursor-default'>Proyectos entregados</span>
                        </div>
                        <div className='text-center mb-10 md:mb-0'>
                            <span className='text-csw-white-50 font-bold text-3xl block cursor-default'>38</span>
                            <span className='text-csw-white-100 cursor-default'>Profesionales a tu disposición</span>
                        </div>
                        <div className='text-center'>
                            <span className='text-csw-white-50 font-bold text-3xl block cursor-default'>5</span>
                            <span className='text-csw-white-100 cursor-default'>años de experiencia</span>
                        </div>
                        <div></div>
                    </div>
                </Container>
                <div className='bg-csw-orange w-full h-100 -mt-50'></div>
            </Section>
            <Section className='bg-csw-dark'>
                <Container>
                    <img src={Logo} alt='Logo' className='inline mb-100' />
                    <div className='grid grid-cols-1 md:grid-cols-4 gap-20'>
                        <div className=''>
                            <span className='text-csw-white-50 font-bold text-xl block uppercase cursor-default'>Cathedral Software</span>
                            <LineIcon className='my-5 mb-10 inline'/>
                            <Text className='text-csw-white-100 cursor-default'>Especialistas en desarrollo a medida de todo tipo de soluciones de alta calidad</Text>
                        </div>
                        <div className='col-span-1 md:col-span-2'>
                            <span className='text-csw-white-50 font-bold text-xl block uppercase cursor-default'>Tecnologías</span>
                            <LineIcon className='mt-5 mb-10 inline'/>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                <div>
                                    <Text className='text-csw-white-100 mb-0 cursor-default'>Cloud</Text>
                                    <Text className='text-csw-white-100 mb-0 cursor-default'>Serless computing</Text>
                                    <Text className='text-csw-white-100 mb-0 cursor-default'>Big data</Text>
                                    <Text className='text-csw-white-100 mb-0 cursor-default'>ETLs</Text>
                                    <Text className='text-csw-white-100 mb-0 cursor-default'>Analítica de datos</Text>
                                    <Text className='text-csw-white-100 mb-0 cursor-default'>Aplicaciones móviles</Text>
                                </div>
                                <div>
                                    <Text className='text-csw-white-100 mb-0 cursor-default'>Visualización de datos</Text>
                                    <Text className='text-csw-white-100 mb-0 cursor-default'>Aplicaciones web</Text>
                                    <Text className='text-csw-white-100 mb-0 cursor-default'>UI / UX</Text>
                                    <Text className='text-csw-white-100 mb-0 cursor-default'>DevOps</Text>
                                    <Text className='text-csw-white-100 mb-0 cursor-default'>Agile methodologies</Text>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <span className='text-csw-white-50 font-bold text-xl block uppercase cursor-default'>Servicios</span>
                            <LineIcon className='mt-5 mb-10 inline'/>
                            <Text className='text-csw-white-100 mb-0 cursor-default'>MVP para Startups</Text>
                            <Text className='text-csw-white-100 mb-0 cursor-default'>Externalización de recursos</Text>
                            <Text className='text-csw-white-100 mb-0 cursor-default'>Desarrollo de software</Text>
                            <Text className='text-csw-white-100 mb-0 cursor-default'>Consultoría</Text>
                        </div>
                        <div className=''>
                            <span className='text-csw-white-50 font-bold text-xl block uppercase cursor-default'>Sedes</span>
                            <LineIcon className='my-5 mb-10 inline'/>
                            <Text className='text-csw-white-50 font-bold cursor-default'>HQ Málaga</Text>
                            <Text className='text-csw-white-100 mb-0 cursor-default'>Calle Marie Curie 12, 1º piso</Text>
                            <Text className='text-csw-white-100 mb-0 cursor-default'>29590 Málaga</Text>
                            <Text className='text-csw-white-50 font-bold'>malaga@cathedralsw.com</Text>
                        </div>
                        <div className='pt-0 md:pt-90'>
                            <Text className='text-csw-white-50 font-bold cursor-default'>Sede Baleares</Text>
                            <Text className='text-csw-white-100 mb-0 cursor-default'>Calle Binissalem, 127</Text>
                            <Text className='text-csw-white-100 mb-0 cursor-default'>07300 Inca</Text>
                            <Text className='text-csw-white-50 font-bold'>baleares@cathedralsw.com</Text>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-2 mt-20'>
                        <div>
                            <Text className='text-csw-white-50'>Copyright 2021 © CATHEDRAL SOFTWARE SL.</Text>
                        </div>
                        <div className='text-right'>
                            <a href='https://es.linkedin.com/company/cathedrals' target='_blank' rel='noreferrer'>
                                <img src={LinkedinIcon} alt='Linkedin img' className='mr-5 inline w-8'/>
                            </a>
                            <a href='mailto:malaga@cathedralsw.com'>
                                <img src={EmailIcon} alt='Linkedin img' className='inline w-8'/>
                            </a>
                        </div>
                    </div>
                </Container>
            </Section>
            {showCookies === 1 ?
                <div className='fixed bottom-0 z-40'>
                    <div className='bg-csw-dark w-full p-10 grid grid-cols-1 md:grid-cols-3 gap-2'>
                        <div className='col-span-1 md:col-span-2'>
                            <Text className='text-csw-white-50 mb-0'>Utilizamos cookies para asegurar que damos la mejor experiencia al usuario en nuestra web. Si sigues utilizando este sitio asumiremos que estás de acuerdo.</Text>
                        </div>
                        <div className='flex justify-end items-center'>
                            <span
                                className='h-fit text-csw-white cursor-pointer font-bold'
                                onClick={() => setModalCookiesState(true)}
                            >Política de privacidad</span>
                            <Button
                                className='h-fit ml-5'
                                onClick={cookiesAction}
                            >Vale</Button>
                        </div>
                    </div>
                </div>
            : null}
            <Modal
                title='Formulario de contacto'
                showModal={modalState}>
                <p className="mb-5">No pierdas la oportunidad de desarrollar tu proyecto con nosotros. Ponte en contacto con nosotros y te escucharemos!</p>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                    <div className='mb-5'>
                        <p className={`font-bold text-csw-dark mb-2`}>Nombre *</p>
                        <Input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Nombre" className="w-full" />
                    </div>
                    <div className='mb-5'>
                        <p className={`font-bold text-csw-dark mb-2`}>Apellidos *</p>
                        <Input type="text" value={surnames} onChange={e => setSurnames(e.target.value)} placeholder="Apellidos" className="w-full" />
                    </div>
                    <div className='mb-5'>
                        <p className={`font-bold text-csw-dark mb-2`}>E-mail *</p>
                        <Input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="E-mail" className="w-full" />
                    </div>
                    <div className='mb-5'>
                        <p className={`font-bold text-csw-dark mb-2`}>Elige una opción *</p>
                        <Select options={selectOptions} value={select} onSelect={setSelect} className="w-full" />
                    </div>
                    <div className='col-span-1 md:col-span-2'>
                        <p className={`font-bold text-csw-dark mb-2`}>Cuéntanos *</p>
                        <Textarea
                            placeholder='Cuéntanos'
                            value={content}
                            onChange={e => setContent(e.target.value)}
                            name='Tell us'
                            className="w-full h-8.5" />
                    </div>
                </div>
                {showValidate ? 
                    <p className='bg-csw-red text-csw-red-50 p-3 text-center mt-5'>Es necesario rellenar los campos obligatorios</p>
                : null}
                <div className="mt-8 inline sm:flex justify-end">
                    <Button
                        className="mr-4 my-5 sm:my-0 w-full sm:w-auto" 
                        type='primary'
                        onClick={() => setModalState(false)}>
                        Cancelar
                    </Button>
                    {sending ?
                        <Button
                            className=' w-full sm:w-auto'
                            >
                            Enviando...
                        </Button>
                    :
                        <Button
                            icon={<ArrowIcon className="ml-3"/>}
                            onClick={sendMail}
                            className='w-full sm:w-auto'
                            >
                            Enviar
                        </Button>
                    }
                </div>
            </Modal>
            <Modal
                title='Política de privacidad'
                showModal={modalCookiesState}>
                <div className=''>
                    <ModalTitle>IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO</ModalTitle>
                    <Text>En cumplimiento del deber de información impuesto en artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, a continuación se indica que, la empresa titular del sitio web es la compañía mercantil “CATHEDRAL SOFTWARE SL” con domicilio fiscal en Calle Binissalem, 127, 07300 Inca (Baleares) y C.I.F.: B16505364. Correo electrónico de contacto del sitio web: baleares@cathedralsw.com y teléfono: 900400445. </Text>
                    
                    <ModalTitle>NORMATIVA APLICABLE EN MATERIA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</ModalTitle>
                    <Text>CATHEDRAL SOFTWARE SL es consciente de la importancia de garantizar el derecho a la intimidad y protección de datos de los Usuarios y, en este sentido, en cumplimiento de lo previsto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD), así como en cumplimiento de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos de Carácter Personal y Garantía de los Derechos Digitales (LOPDGDD), se informa a continuación sobre los siguientes aspectos relacionados con el tratamiento de datos de carácter personal a través del presente sitio web.</Text>

                    <ModalTitle>FINALIDAD DEL TRATAMIENTO Y NATURALEZA DE LOS DATOS RECABADOS</ModalTitle>
                    <Text>En función del formulario que en cada caso complete el Usuario, los datos podrán ser tratados con las siguientes finalidades:</Text>
                    <ul>
                        <li className='pl-10'><Text>- Envío de comunicaciones informativas: En los supuestos en los que el interesado haya manifestado su consentimiento, sus datos se utilizarán con el fin de remitirle comunicaciones informativas sobre nuestros productos y servicios. La información recabada se limitará a datos identificativos y dirección de correo electrónico.</Text></li>
                        <li className='pl-10'><Text>- Apartado contacto: En el caso de que el Usuario complete el formulario de contacto, sus datos se utilizarán con la finalidad de atender la consulta o petición de información que en su caso solicite. Los datos recabados en este caso son de naturaleza identificativa y de contacto (nombre, apellidos y email), así como datos relacionados con la consulta o petición planteada por el Usuario.</Text></li>
                        <li className='pl-10'><Text>- Apartado trabaja en Cathedral Software SL: En los casos en los que el Usuario nos facilite sus datos curriculares, su información será utilizada para hacerle partícipe en los procesos de selección organizados por CATHEDRAL SOFTWARE SL. En este apartado se recabarán los datos identificativos, datos de contacto, datos relativos a la formación académica de Usuario y experiencia profesional.</Text></li>
                    </ul>
                    <ModalTitle>VERACIDAD DE LA INFORMACIÓN APORTADA</ModalTitle>
                    <Text>Toda la información que facilite el Usuario deberá ser veraz y exacta. A estos efectos, el interesado garantiza la autenticidad de todos aquellos datos que aporte como consecuencia de la cumplimentación de los formularios correspondientes. En todo caso, el Usuario será el único responsable de las manifestaciones falsas o inexactas que realice, y de los perjuicios que cause a CATHEDRAL SOFTWARE SL o a terceros, por la información que facilite.</Text>
                    
                    <ModalTitle>BASE QUE LEGITIMA EL TRATAMIENTO DE DATOS</ModalTitle>
                    <Text>La base que legitima el tratamiento de datos obtenidos a través de los formularios del presente sitio web es el propio consentimiento y aceptación del Usuario en cada uno de los apartados en los que se recopila información de carácter personal. El Usuario tiene derecho a retirar el consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</Text>
                    <Text>La base que legitima el tratamiento de datos de Clientes es la gestión de la relación contractual entre las partes.</Text>
                    
                    <ModalTitle>CONSERVACIÓN DE LOS DATOS</ModalTitle>
                    <ul>
                        <li className='pl-10'><Text>- Envío de comunicaciones informativas: Los datos se conservarán de manera indefinida, a menos que el interesado manifieste su oposición a la recepción de dichas comunicaciones.</Text></li>
                        <li className='pl-10'><Text>- Apartado contacto: La información se conservará durante el plazo necesario para atender la petición de consulta o información que haya solicitado el Usuario; posteriormente la información se mantendrá debidamente bloqueada durante el plazo de conservación de cinco años y posteriormente será eliminada.</Text></li>
                        <li className='pl-10'><Text>- Datos de solicitantes de empleo: Los datos de candidatos a puestos de trabajo se conservarán durante un periodo de dos años. Superado dicho plazo, procederemos al borrado de la información.</Text></li>
                    </ul>

                    <ModalTitle>EJERCICIO DE SUS DERECHOS</ModalTitle>
                    <Text>El Usuario tiene derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. El Usuario podrá solicitar la limitación del tratamiento de sus datos, en cuyo caso CATHEDRAL SOFTWARE SL solo conservará para el ejercicio o la defensa de reclamaciones.  En determinadas circunstancias y por motivos relacionados con su situación particular, el Usuario podrá oponerse al tratamiento de sus datos, en cuyo caso, CATHEDRAL SOFTWARE SL solo conservará para el ejercicio o la defensa de reclamaciones. Por último, le informamos sobre su derecho de portabilidad previsto en el RGPD con el fin de que sus datos puedan ser facilitados al responsable del tratamiento que el Usuario nos indique. </Text>
                    <Text>En los casos en los que considere que durante el tratamiento de datos por parte de CATHEDRAL SOFTWARE SL se han vulnerado los derechos en materia de protección de datos del interesado, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, el Usuario puede presentar una reclamación ante la Autoridad de Control de Protección de Datos competente. </Text>

                    <ModalTitle>SEGURIDAD DE LA INFORMACIÓN</ModalTitle>
                    <Text>CATHEDRAL SOFTWARE SL utiliza tecnologías adecuadas al estado de la técnica actual, para proteger sus datos e informaciones personales, así nuestra página web se almacena en servidores seguros protegidos contra los tipos de ataques más habituales. No obstante, le recordamos que no existe la tecnología invulnerable y que por tanto debe poner los medios que estén a su alcance para mantener el nivel de seguridad de sus datos. CATHEDRAL SOFTWARE SL ha adoptado las medidas de índole técnica y organizativa que garantizan la seguridad de los datos de carácter personal y evitan su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que estén expuestos.</Text>

                    <ModalTitle>CAMBIOS EN LA PRESENTE POLÍTICA DE PRIVACIDAD</ModalTitle>
                    <Text>CATHEDRAL SOFTWARE SL se reserva el derecho a modificar la presente Política de Privacidad para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria. En dichos supuestos, CATHEDRAL SOFTWARE SL anunciará en esta página los cambios introducidos con razonable antelación a su puesta en práctica.</Text>
                    
                    <ModalTitle>POLÍTICA DE COOKIES</ModalTitle>
                    <Text> El Sitio Web puede usar cookies, pequeños ficheros de texto con información sobre su navegación en este sitio, cuyo principal propósito es mejorar la experiencia del usuario en la web. Al continuar la navegación por el Sitio Web, el usuario acepta – en función de la configuración que haya seleccionado sobre las opciones de privacidad facilitadas por su navegador – la instalación de las cookies utilizadas en este Sitio Web.</Text>

                    <Text>Fecha de la última actualización: 13/05/2020</Text>

                </div>
                <div className="mt-8 inline sm:flex justify-end">
                    <Button
                        className="mr-4 my-5 sm:my-0 w-full sm:w-auto" 
                        type='primary'
                        onClick={() => setModalCookiesState(false)}>
                        Cancelar
                    </Button>
                    {sending ?
                        <Button
                            className=' w-full sm:w-auto'
                            >
                            Enviando...
                        </Button>
                    :
                        <Button
                            icon={<ArrowIcon className="ml-3"/>}
                            onClick={cookiesAction}
                            className='w-full sm:w-auto'
                            >
                            Aceptar
                        </Button>
                    }
                </div>
            </Modal>
        </>
    )
}