import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
//import cookiesReducer from './cookies';

const initialState = {
};

const rootReducer = combineReducers({
    //cookies: cookiesReducer,
});

let middlewares;
if (process.env.NODE_ENV === 'development') {
    // When development is enabled allow debugging of redux changes with the "Redux DevTools".
    middlewares = composeWithDevTools();
}

const store = createStore(rootReducer, initialState, middlewares);
const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});

export { history, store };
