import React from 'react'
import PropTypes from 'prop-types'

export const Container = ({ className, children }) => {

    return (
        <div className={`container px-10 mx-auto ${className}`}>
            {children}
        </div>
    );
};

Container.propTypes = {
    className: PropTypes.string,
};