import React from 'react';
import PropTypes from 'prop-types';

export const Input = ({ type, placeholder, className, onChange, name, value, disabled }) => {

    const style = disabled ? 'text-csw-grey bg-csw-grey-50' : 'text-csw-grey bg-csw-white-50'
    return (
        <>
            <input
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                disabled={disabled}
                className={`py-3 px-4 focus:outline-none ${className} ${style}`} />
        </>
    );
};

Input.propTypes = {
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
};