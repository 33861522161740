import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const Button = ({ type, size, icon, children, disabled, onClick, className, style, id, link }) => {
    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
    };
    const typeClass =
        type === 'primary' ? 'bg-csw-dark' :
        'bg-csw-orange'

    let sizeClass;
    switch (size) {
        case 'big':
            sizeClass = 'w-full py-5 px-7';
            break;
        case 'small':
            sizeClass = 'w-min py-2 px-7';
            break;
        case 'medium':
            sizeClass = 'py-5 px-10';
            break;
        case 'icon':
            sizeClass = 'py-5 px-7 h-0 justify-items-center';
            break;
        default:
            sizeClass = 'py-5 px-7';
    }
    
    const btnIcon = icon
        ? ''
        : 'grid justify-items-center'
      
    const generalClass = `font-bold ase-in duration-300 shadow-xl hover:shadow-none rounded-3xl leading-3 text-csw-white active:text-csw-white focus:text-csw-white ${typeClass} ${sizeClass} ${btnIcon} ${className}`

    return (
        <>
            { link ? 
                <Link
                    to={link}
                    id={id}
                    className={generalClass}
                    disabled={disabled}
                    style={style}>
                        <span className='flex items-center'>
                            {icon !== null ? <span>{icon}</span> : null}
                            {children}
                        </span>
                </Link>
                :
                <button
                    id={id}
                    className={generalClass}
                    onClick={handleClick}
                    disabled={disabled}
                    style={style}>
                    <span className='flex items-center'>
                        {children}
                        {icon !== null ? <span>{icon}</span> : null}
                    </span>
                </button>
            }
        </>
    );
};

Button.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.any,
    icon: PropTypes.element,
    type: PropTypes.oneOf(['primary', '']),
    size: PropTypes.oneOf(['big', 'medium', 'small', 'icon', '']),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    id: PropTypes.string
};

Button.defaultProps = {
    className: ''
};
