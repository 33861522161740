import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

export const Select = ({ options, className, value, onSelect, disabled }) => {

    const customStyles = {
        control: () => ({
            cursor: 'pointer',
            lineHeight: '12px',
            display: 'flex',
            padding: '8px',
            color: '#bd7407',
            background: '#E7E8EB',
            borderRadius: 6,
            svg: {
                height: 12,
                paddingTop: '1px',
                path: {
                    fill: '#000000'
                }
            },
        }),
        option: (provided, { isSelected }) => ({
            ...provided,
            '&:hover': {
                backgroundColor: '#ffffff',
                color: '#000000',
                cursor: 'pointer',
            },
            backgroundColor: 'white',
            color: isSelected ? '#000000' : '#CACACA',
            paddingLeft: '5px',
            paddingRight: '5px',
            marginLeft: '5px',
            marginRight: '5px',
        }),
    };

    const [selected, setSelected] = useState(null);
    useEffect(() => {
        const valueSelected = options.find((option) => option.value === value);
        setSelected(valueSelected ?? '');
    }, [options, value]);

    const onChange = (option) => {
        return onSelect !== null ? onSelect(option.value) : () => {}
    };

    return (
        value != null ?
            <ReactSelect
                options={options}
                styles={customStyles}
                className={className}
                onChange={onChange}
                isDisabled={disabled}
                value={selected}
            />
        :
            <ReactSelect
                options={options}
                styles={customStyles}
                className={className}
                onChange={onChange}
                isDisabled={disabled}
            />
    );
};

Select.propTypes = {
    options: PropTypes.array,
    className: PropTypes.string,
    value: PropTypes.any,
    onSelect: PropTypes.func,
};