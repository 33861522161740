import React from 'react'

export function Modal({ title, showModal, children }) {

    return (
        <>
            {showModal ? (
                <div className="justify-center inline md:flex overflow-x-hidden overflow-y-auto bg-csw-dark fixed inset-0 z-50 outline-none focus:outline-none bg-opacity-50">
                    <div className="flex w-auto max-w-2xl my-0 md:my-6 px-5 lg:px-0">
                        <div className="md:flex flex-col border-0 shadow-lg p-10 h-fit overflow-y-auto relative bg-csw-white w-auto outline-none focus:outline-none">
                            <div className="flex items-start justify-between">
                                <div className="flex">
                                    <p className="pb-5 font-bold text-xl text-csw-dark">{title}</p>
                                </div>
                            </div>
                            <div className="">{children}</div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}